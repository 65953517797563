/*.modal-content {*/
/*  border-radius: 0px;*/
/*  width: 90vw;   !* Occupy the 90% of the screen width *!*/
/*  max-width: 90vw;*/
/*}*/

.full-screen-modal {
  width: 90vw;
  max-width: 90vw;
  height: 90vh;
  max-height: 90vh;
}

.full-screen-modal > .modal-content {
  height: 100%;
  border-radius: 15px 15px 15px 15px;
}

.full-screen-modal .modal-title {
  width: 100%;
}

.full-screen-modal .header-item {
  padding: 10px 15px;
  width: 100%;
  justify-content: center;
}

.full-screen-modal .header-subtitle {
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.full-screen-modal .modal-body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.full-screen-modal .modal-header .btn-close {
  margin: 0 !important;
  margin-right: 10px !important;
  font-size: 10px;
  padding: 10px;
  border: 1px solid rgb(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  left: calc(100% - 45px);
  top: 15px;
}

.full-screen-modal .navigation-btn {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #3cc2c8;
  margin: auto;
}

.full-screen-modal .navigation-btn svg {
  font-size: 26px;
}

.full-screen-modal .header-subtitle {
  padding: 0 15px;
}

.full-screen-modal .spinner {
  margin-top: 20px !important;
  display: flex;
  margin: auto;
  width: 50px;
  height: 50px;
  color: #3cc2c8;
  --bs-spinner-border-width: 4px;
}
