.header {
  background-color: #404040;
  color: #fff !important;
  height: 100px;
  font-size: 18px;
  text-align: center;
}

.header-logo {
  font-size: 26px;
  font-weight: bold;
}

.header-sublogo {
  font-size: 14px;
}

.logo {
  width: 41px;
  height: 40px;
}

.logo svg {
  width: inherit;
  height: inherit;
}
