.add-mailbox-modal {
  width: 590px;
  max-width: 80vw;
}

.add-mailbox-modal #add-auth-title {
  /*text-transform: capitalize;*/
}

.add-mailbox-modal .modal-content {
  height: 100%;
  border-radius: 40px !important;
}

.add-mailbox-modal .header-item {
  padding: 10px 15px;
  width: 100%;
  justify-content: center;
}

.add-mailbox-modal .header-subtitle {
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.add-mailbox-modal .modal-body {
  padding: 0;
  margin: 0;
  width: 100%;
  /*height: 500px;*/
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.add-mailbox-modal .modal-header .btn-close {
  margin: 0 !important;
  margin-right: 20px !important;
  font-size: 10px;
  padding: 10px;
  border: 1px solid rgb(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  left: calc(100% - 55px);
  top: 25px;
}

.add-mailbox-modal .modal-header {
  height: 85px;
}

.add-mailbox-modal .navigation-btn {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #3cc2c8;
  margin: auto;
}

.add-mailbox-modal .navigation-btn svg {
  font-size: 26px;
}

.add-mailbox-modal .header-subtitle {
  padding: 0 15px;
}

.add-mailbox-modal .spinner {
  margin-top: 20px !important;
  display: flex;
  margin: auto;
  width: 50px;
  height: 50px;
  color: #3cc2c8;
  --bs-spinner-border-width: 4px;
}
